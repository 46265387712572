@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-light: 42,91,78;
  --foreground-color-light: rgb(var(--foreground-light));  
  --foreground-light-color-light-900: rgb(26, 58, 49);
  
  --foreground-dark: 212, 255, 238;
  --foreground-color-dark: rgb(var(--foreground-dark));
  --foreground-dark-color-light-900: rgb(255, 255, 255);

  --background-light: 254,252,245;
  --background-color-light: rgb(var(--background-light));
  --background-color-light-50: rgb(242, 242, 240);

  --background-dark: 8, 31, 25;
  --background-color-dark: rgb(var(--background-dark));
  --background-color-dark-50: rgb(23, 41, 35);

  --foreground-light-contrast: 0, 0, 0;
  --foreground-color-light-contrast: rgb(var(--foreground-light-contrast));  
  --foreground-light-color-light-900-contrast: rgb(0, 0, 0);

  --background-light-contrast: 255,255,255;
  --background-color-light-contrast: rgb(var(--background-light-contrast));
  --background-color-light-50-contrast: rgba(244, 244, 244);

  --foreground-dark-contrast: 255, 255, 255;
  --foreground-color-dark-contrast: rgb(var(--foreground-dark-contrast));
  --foreground-dark-color-light-900-contrast: rgb(255, 255, 255);

  --background-dark-contrast: 0, 0, 0;
  --background-color-dark-contrast: rgb(var(--background-dark-contrast));
  --background-color-dark-50-contrast: rgba(4, 4, 4);
}

.light[data-adaptify-high-contrast="disabled"] {
  --background: var(--background-light);
  --background-color: var(--background-color-light);
  --background-color-50: var(--background-color-light-50);

  --foreground: var(--foreground-light);
  --foreground-color: var(--foreground-color-light);
  --foreground-color-900: var(--foreground-light-color-light-900);
}

.dark[data-adaptify-high-contrast="disabled"] {
  --background: var(--background-dark);
  --background-color: var(--background-color-dark);
  --background-color-50: var(--background-color-dark-50);

  --foreground: var(--foreground-dark);
  --foreground-color: var(--foreground-color-dark);
  --foreground-color-900: var(--foreground-dark-color-light-900);
}

.dark[data-adaptify-high-contrast="enabled"] {
  --background: var(--background-dark-contrast);
  --background-color: var(--background-color-dark-contrast);
  --background-color-50: var(--background-color-dark-50-contrast);

  --foreground: var(--foreground-dark-contrast);
  --foreground-color: var(--foreground-color-dark-contrast);
  --foreground-color-900: var(--foreground-dark-color-light-900-contrast);
}

.light[data-adaptify-high-contrast="enabled"] {
  --background: var(--background-light-contrast);
  --background-color: var(--background-color-light-contrast);
  --background-color-50: var(--background-color-light-50-contrast);

  --foreground: var(--foreground-light-contrast);
  --foreground-color: var(--foreground-color-light-contrast);
  --foreground-color-900: var(--foreground-light-color-light-900-contrast);
}